/*Variables*/
$primary-color-dark:   #0288D1;
$primary-color:        #03A9F4;
$primary-color-light:  #B3E5FC;
$primary-color-text:   #FFFFFF;
$accent-color:         #448AFF;
$primary-text-color:   #212121;
$secondary-text-color: #757575;
$divider-color:        #BDBDBD;
#root, body {
    display: flex;
    flex: 1;
    flex-direction: column;
    height: 100vh;
}
